@font-face {
  font-family: "Geist";
  src: url("./fonts/Geist-VF.woff2") format("woff2");
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: "Geist Mono";
  src: url("./fonts/GeistMono-VF.woff2") format("woff2");
  font-weight: 100 900;
  font-style: normal;
}
